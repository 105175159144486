export type GCPProjectFirebaseFeature = "DEFAULT_STORAGE" | "FIRESTORE" | "FUNCTIONS";

export const locations: Record<string, Omit<GCPProjectLocation, "locationId">> = {
    "europe-west": {
        name: "Europe, multi region",
        group: "Europe",
        type: "MULTI_REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-central": {
        name: "North America, multi region",
        group: "North America",
        type: "MULTI_REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-west1": {
        name: "Oregon",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-west2": {
        name: "Los Angeles",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-west3": {
        name: "Salt Lake City",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-west4": {
        name: "Las Vegas",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "northamerica-northeast1": {
        name: "Montréal",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-east1": {
        name: "South Carolina",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "us-east4": {
        name: "Northern Virginia",
        group: "North America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "southamerica-east1": {
        name: "São Paulo",
        group: "South America",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "europe-west1": {
        name: "Belgium",
        group: "Europe",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "europe-west2": {
        name: "London",
        group: "Europe",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "europe-west3": {
        name: "Frankfurt",
        group: "Europe",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "europe-west6": {
        name: "Zürich",
        group: "Europe",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "europe-central2": {
        name: "Warsaw",
        group: "Europe",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-east1": {
        name: "Taiwan",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-east2": {
        name: "Hong Kong",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-northeast1": {
        name: "Tokyo",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-northeast2": {
        name: "Osaka",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-northeast3": {
        name: "Seoul",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-south1": {
        name: "Mumbai",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-southeast1": {
        name: "Singapore",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "asia-southeast2": {
        name: "Jakarta",
        group: "Asia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    },
    "australia-southeast1": {
        name: "Sydney",
        group: "Australia",
        type: "REGIONAL",
        features: ["DEFAULT_STORAGE", "FIRESTORE", "FUNCTIONS"]
    }
};

export type GCPProjectLocation = {
    "name": string,
    "group": string,
    "locationId": keyof typeof locations,
    "type": "REGIONAL" | "MULTI_REGIONAL",
    "features": GCPProjectFirebaseFeature[]
}

export function getFallbackLocations(): GCPProjectLocation[] {
    return Object.entries(locations).map(([locationId, location]) => ({
        locationId: locationId,
        ...location
    }));
}
