import { ProLicense, useLicensesForUserController, } from "@firecms/cloud";

import { AddIcon, Button, Paper, Typography, } from "@firecms/ui";
import { useCallback, useState } from "react";
import { LicenseDetailsForm } from "./LicensesDetailsForm";
import { ProLicenseView } from "./ProLicenseView";

export function ProLicenses() {

    const [dialogOpen, setDialogOpen] = useState<boolean>();
    const [selectedProLicense, setSelectedProLicense] = useState<ProLicense | undefined>();

    const onMissingSubscriptionClick = useCallback((license: ProLicense) => {
        setSelectedProLicense(license);
        setDialogOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setDialogOpen(false);
        setSelectedProLicense(undefined);
    }, []);

    const { licenses } = useLicensesForUserController();

    return <>
        <div className={"mt-16 mb-8"}>
            <div
                className="flex items-center mt-12 ">

                <Typography variant={"h4"} className={"flex-grow"}>Your PRO licenses</Typography>

                <Button
                    startIcon={<AddIcon/>}
                    onClick={() => setDialogOpen(true)}>
                    Create new license
                </Button>
            </div>
        </div>

        <Typography variant={"body1"} className={"my-4"}>
            Create subscriptions in this section only for <b>self hosted</b> FireCMS PRO. If you are using FireCMS
            Cloud, you can upgrade your project from within the project settings.
        </Typography>

        <Typography>
            If you are an agency, you are free to <b>resell</b> your license to your customers.
        </Typography>

        <Typography variant={"body1"} className={"my-4"}>
            If you have any questions or need help, please contact us at <a href="mailto:hello@firecms.co">
            hello@firecms.co</a>
        </Typography>

        <div className={"flex flex-col gap-4"}>
            {licenses && licenses.length > 0 &&
                licenses.map((license) => (
                    <ProLicenseView key={license.id}
                                    license={license}
                                    onMissingSubscriptionClick={onMissingSubscriptionClick}
                    />
                ))}
            {(!licenses || licenses.length === 0) &&
                <Paper className={"p-4"}>
                    <Typography variant={"label"}>You have not created any FireCMS PRO licenses yet</Typography>
                </Paper>}

        </div>

        <LicenseDetailsForm
            key={selectedProLicense?.id ?? "new"}
            open={dialogOpen ?? false}
            license={selectedProLicense}
            handleClose={handleClose}/>

    </>;
}
